import { LitElement, css, html, nothing } from 'lit'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { capFirst } from '#js/components/utils'
import { formPost } from '#js/components/http'
import { setLocale } from '#js/components/lit-i18n'

window.dataLayer = window.dataLayer || []

class HelpRequest extends LitElement {
  static get properties () {
    return {
      apiUrl: { type: String },
      phone: { type: String },
      errors: { type: Object, attribute: false },
      isFinished: { type: Boolean, attribute: false, default: false },
      isSubmissionDisable: { type: Boolean, attribute: false },
      contactMethod: { type: String, attribute: false },
      preferredTime: { type: String, attribute: false }
    }
  }

  static styles = css`
    textarea {
      resize: vertical;
      min-height: 200px;
      max-height: 400px;
    }
    .error {
      color: red;
      font-size: 0.8em;
    }
  `

  constructor () {
    super()
    setLocale(window.language)
    updateWhenLocaleChanges(this)
    this.errors = {}
  }

  connectedCallback () {
    super.connectedCallback()
    document.addEventListener('modal-opened', this.handleModalOpened.bind(this))
  }

  disconnectedCallback () {
    super.disconnectedCallback()
    this.removeEventListener('modal-opened', this.handleModalOpened.bind(this))
  }

  handleModalOpened (event) {
    if (!this.isFinished) {
      window.dataLayer.push({
        event: 'open_help_request'
      })
    }
  }

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      ${this.isFinished ? this.renderSuccessMessage() : this.renderContent()}
    `
  }

  renderSuccessMessage () {
    return html`
      <p>
        ${msg(str`Thank you for your contact enquiry. We will get back to you within 24 hours (working days) via your preferred contact method.`)}
      </p>
    `
  }

  renderContent () {
    return html`
      <div class="column">
        <small class="subtitle">
          ${msg(str`Please enter the details of your enquiry so that our service team can provide you with the best possible support.`)}
        </small>
        <form class="material" @submit="${this.handleSubmit}" submit>
          <fieldset>
            ${this.renderContactMethod()}
            ${(this.contactMethod === 'phone') ? this.renderContactInfo() : nothing}
          </fieldset>
          <p>
            <textarea
              name="description"
              placeholder="${capFirst(msg(str`describe your concern in a few words`))}"
            ></textarea>
            <label for="description">${capFirst(msg(str`description`))}:</label>
          </p>
          <div class="form-footer">
            <button
              type="submit"
              class="button button--filled"
              ?disabled="${Object.keys(this.errors).length > 0 || this.isSubmissionDisable}">
              ${capFirst(msg(str`send request`))}
            </button>
          </div>
        </form>
      </div>
    `
  }

  async handleSubmit (event) {
    event.preventDefault()
    this.isSubmissionDisable = true

    const formData = new FormData(event.target)
    const data = {}

    for (const [key, value] of formData.entries()) {
      data[key] = value
    }

    try {
      const url = new URL(this.apiUrl, window.location.origin)
      const response = await formPost(url, data)

      if (response.ok) {
        this.isFinished = true
        window.dataLayer.push({
          event: 'submit_help_request'
        })
      } else {
        const errorData = await response.json()
        this.errors = errorData
      }
    } catch (error) {
      console.error('Help request submission failed:', error)
    }
  }

  renderContactMethod () {
    return html`
      <p>
        <select name="contact_method" @change="${this.contactMethodChanged}">
          <option value="email" ?selected="${this.contactMethod === 'email'}">
            ${capFirst(msg(str`email`))}
          </option>
          <option value="phone" ?selected="${this.contactMethod === 'phone'}">
            ${capFirst(msg(str`phone`))}
          </option>
        </select>
        <label for="contact_method">
          ${capFirst(msg(str`preferred contact method`))}:
        </label>
      </p>
    `
  }

  renderError (field) {
    if (!this.errors || !this.errors[field]) {
      return nothing
    } else {
      return html`<div class="error">${this.errors[field].join(', ')}</div>`
    }
  }

  contactMethodChanged (event) {
    this.contactMethod = event.target.value

    const formData = new FormData(this.shadowRoot.querySelector('form'))
    this.phone = formData.get('phone') || this.phone
    this.preferredTime = formData.get('preferred_time') || this.preferredTime
  }

  renderContactInfo () {
    return html`
      <fieldset class="form__row">
        <p>
          <input
            type="text"
            name="phone"
            placeholder="${capFirst(msg(str`enter your phone number`))}"
            .value="${this.phone}"
            @input="${(e) => { this.errors = {}; this.isSubmissionDisable = false }}"
            required
          >
          <label for="phone">${capFirst(msg(str`phone`))}:</label>
          ${this.renderError('phone')}
        </p>
        <p>
          <select name="preferred_time">
            <option value="anytime" ?selected="${this.preferredTime === 'any'}">
              ${capFirst(msg(str`anytime`))}
            </option>
            <option value="morning" ?selected="${this.preferredTime === 'morning'}">
              ${capFirst(msg(str`morning`))}
            </option>
            <option value="noon" ?selected="${this.preferredTime === 'noon'}">
              ${capFirst(msg(str`noon`))}
            </option>
            <option value="afternoon" ?selected="${this.preferredTime === 'afternoon'}">
              ${capFirst(msg(str`afternoon`))}
            </option>
            <option value="evening" ?selected="${this.preferredTime === 'evening'}">
              ${capFirst(msg(str`evening`))}
            </option>
          </select>
          <label for="preferred_time">
            ${capFirst(msg(str`preferred contact time`))}:
          </label>
        </p>
      </fieldset>
    `
  }
}

window.customElements.define('help-request', HelpRequest)
